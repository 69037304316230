import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import pic4 from '../assets/images/fitness_consultation_660.jpg';
import pic5 from '../assets/images/two_outside_660.jpg';
import config from '../../config';
// import social from '../assets/img/nikki_social.jpg';
const book = () => (
  <Layout>
    <Helmet>
      <meta
        name="description"
        content="Book an initial consultation, small group, or personal training session with Nikki Van Culin in Lake County, FL. Sessions are offered in person and virtually (via Zoom)."
      />
      <meta property="og:image" content="https://i.imgur.com/WXEH5Wp.jpg" />
      <meta
        property="og:image:alt"
        content="Nikki Van Culin personal trainer at Envision Wellness logo"
      />
    </Helmet>
    <div id="wrapper">
      <section id="banner">
        <div className="inner book-hero">
          <div className="hero-section">
            <h1 className="book-heading">{config.heading}</h1>

            <h2>{config.subHeading}</h2>
            <AnchorLink
              to="/book#book"
              title="Book Sessions &rarr;"
              className="stripped hero-link"
              stripHash
            />
          </div>
        </div>
      </section>

      <div className="wrapper subpage" id="subpage">
        <div className="inner">
          <h3 className="major" id="book">
            Let's Do This!
          </h3>
          <p className="major-text">
            Select the type of session you would like to book below and fill out
            the form indicating your availability and method of payment. If this
            is your first session, please book a fitness consultation so that we
            can discuss your goals and make a plan that will fit your needs. All
            sessions are currently available in person and online (via Zoom).
          </p>
          <section className="features">
            <article className="book-article">
              <h4 className="major-subhead" id="major-subhead-book">
                Consultation
              </h4>
              <hr className="card-break" />

              <ul className="services-p pricing-list">
                <li>Initial consultation is free!</li>
              </ul>

              <ul className="services-p pricing-list book-links">
                <li className="waiver">
                  <a
                    href="https://forms.gle/rDfTAfPSniAPK3hA6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Waiver & Intake
                  </a>
                </li>
              </ul>
            </article>
            <article className="book-article">
              <h4 className="major-subhead" id="major-subhead-book">
                One-on-One
              </h4>
              <hr className="card-break" />

              <ul className="services-p pricing-list">
                <li>One hour starting at $55</li>
                <li>Half hour starting at $30</li>
              </ul>

              <ul className="services-p pricing-list book-links">
                <li>
                  <a
                    href="https://forms.gle/szcFhcPusLxjh3KZA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Current week
                  </a>
                </li>
                <li>
                  <a
                    href="https://forms.gle/VRHNj7fQNW8LPmS16"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Next week
                  </a>
                </li>
              </ul>
            </article>
            <article className="book-article">
              <h4 className="major-subhead" id="major-subhead-book">
                Small Group
              </h4>
              <hr className="card-break" />

              <ul className="services-p pricing-list">
                <li>One hour class $12</li>
              </ul>

              <ul className="services-p pricing-list schedule">
                <li>Mondays @ 6pm</li>
                <li>Tuesdays @ 8:15am</li>
                <li>Tuesdays @ 6pm</li>
                <li>Wednesdays @ 6pm</li>
                <li>Thursdays @ 6pm</li>
                <li>Fridays @ 8:15am</li>
              </ul>

              <ul className="services-p pricing-list book-links">
                <li>
                  <a
                    href="https://forms.gle/J742MismF4ooo3pK7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Current week
                  </a>
                </li>
                <li>
                  <a
                    href="https://forms.gle/5DAhZaJU1YWvDhTe6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Next week
                  </a>
                </li>
              </ul>
            </article>
          </section>
        </div>
      </div>
    </div>
  </Layout>
);

export default book;
